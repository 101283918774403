<template>
  <v-card flat min-height="100vh" color="transparent">
    <v-card-title class="xflex xflex-row xjustify-center xitems-center xw-full">
      <Filters
        :value="selectedRange"
        @change="navigate"
        type="custom"
      ></Filters>
    </v-card-title>
    <v-progress-linear
      indeterminate
      v-if="fetching"
      :height="10"
      class="my-1 xw-full"
      color="primary"
    ></v-progress-linear>
    <Iterators
      v-else
      class="px-4"
      empty-text="No records found for the selected range of dates"
      :data-array="records"
      type="daily"
    >
    </Iterators>
    <template>
      <div
        class="md:xh-[60px] xh-[100px] xitems-center xw-full xpy-2 xpx-[1em] xtext-gray-600"
      >
        <div
          class="xflex xflex-1 md:xflex-row xflex-col xjustify-between xitems-center"
        >
          <div
            class="xflex md:xw-7/12 xw-full xflex-row md:xjustify-start xjustify-center xtext-[13px] xgap-1 xitems-center"
          >
            <span class="xfont-semibold">Slack Status :</span>
            <v-icon color="gray">mdi-circle-medium</v-icon> Out
            <v-icon color="#facc15">mdi-circle-medium</v-icon> Brb
            <v-icon color="#92dac3">mdi-circle-medium</v-icon> Online
          </div>
          <v-divider class="xw-full xmy-1" v-if="smDown"></v-divider>
          <div
            class="xflex md:xw-5/12 xgap-[0.5em] xw-full xflex-row xjustify-start xitems-center"
          >
            <div
              class="xw-6/12 xflex xflex-col xjustify-start xitems-stretch"
            >
              <span class="xleading-[20px] xtext-[12px] xfont-[500]">
                Total Work Time
              </span>
              <span class="xfont-bold">
                {{ totalWork | secToHuman(" hours", " minutes") }}
              </span>
            </div>
            <div
              class="xw-6/12 xflex xflex-col xjustify-start xitems-stretch"
            >
              <span class="xleading-[20px] xtext-[12px] xfont-[500]">
                Total Break Time
              </span>
              <span class="xfont-bold">
                {{ totalBreak | secToHuman(" hours", " minutes") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- <pre>{{ records }}</pre> -->
  </v-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import Filters from "../components/Filters.vue";
import Iterators from "../components/Iterators.vue";
export default {
  components: { Filters, Iterators },
  data() {
    return {
      fetching: false,
      records: [],
      selectedRange: [
        moment().subtract(1, "day").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    };
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        this.selectedRange = val
          ? [val.start, val.end]
          : [
              moment().subtract(1, "day").format("YYYY-MM-DD"),
              moment().format("YYYY-MM-DD"),
            ];
        val && this.fetchSummary(val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    selectedId() {
      return this.$route.params.id;
    },
    totalWork() {
      return _.sumBy(_.cloneDeep(this.records).map((i) => i.summary.works));
    },
    totalBreak() {
      return _.sumBy(_.cloneDeep(this.records).map((i) => i.summary.breaks));
    },
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: "app-user-record-custom",
          params: { id: this.selectedId, start: val[0], end: val[1] },
        })
        .catch(() => {});
    },
    fetchSummary(range) {
      this.fetching = true;
      this.$axios
        .get(
          `api/user-records/${this.selectedId}/custom/${range.start}/${range.end}`
        )
        .then(({ data }) => {
          this.records = data.summaries;
        })
        .finally(() => (this.fetching = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
